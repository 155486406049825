
































































































































































































































































































































































































.menu__item {
    width: 100%;
    display: block;
    line-height: 20px;
    text-align: center;
    margin-top: 10px;
    text-align: center;

}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
    border-color :#3d77eb;
}
.el-checkbox__input.is-disabled+span.el-checkbox__label{
    color:#3d77eb
}
